export const en = {
  Interaction: "Interaction",
  Process: "Process",
  Processs: "Process",
  Integration: "Integration",
  Services: "Services",
  Service: "Services",
  Data: "Data",
  Dependencies: "Dependencies",
  Components: "Components",
  Standards: "Standards",
  Standard: "Standard",
  Suppliers: "Suppliers",
  Reuse: "Reuse",
  Processes: "Processes",
  Products: "Products",
  Name: "Name",
  Repository: "Repository",
  Organization: "Organization",
  Layer: "Layer",
  Layers: "Layers",
  Type: "Type",
  Status: "Status",
  Details: "Details",
  Table: "Table",
  Cards: "Cards",
  Search: "Search",
  Installations: "Installations",
  Software: "Software",
  Installation: "Installation",
  Use: "Use",
  API: "API",
  Reuser: "Reuser",
  interface: "Interface",
  Interface: "Interface",
  Username: "Username",
  Password: "Password",
  Send: "Send",
  Private: "Private",
  Public: "Public",
  Unknown: "Unknown",
  Relations: "Relations",
  About: "About",
  Documentation: "Documentation",
  Warning: "Warning",
  Version: "Version",
  Close: "Close",
  Initiatives: "Initiatives",
  Development: "Development",
  Stable: "Stable",
  Obsolete: "Obsolete",
  User: "User",
  Login: "Login",
  Logout: "Logout",
  Applications: "Applications",
  Categories: "Categories",
  Category: "Category",
  Support: "Support",
  Solutions: "Solutions",
  Control: "Control",
  Surveillance: "Surveillance",
  Directing: "Directing",
  Execution: "Execution",
  Sources: "Sources",
  Application: "Application",
  Contact: "Contact",
  Publications: "Publications",
  Usage: "Usage",
  Breadcrumbs: "Breadcrumbs",
  Page: "Page",
  Limit: "Limit",
  Email: "E-mail",
  Unavailable: "Unavailable",
  Rating: "Rating",
  Description: "Description",
  Gold: "Gold",
  Silver: "Silver",
  Bronze: "Bronze",
  Configurations: "Configurations",
  "Open Catalogs": "Open Catalogs",
  "Reusable components within the government": "Reusable components within the government",
  "Information Models": "Information Models",
  "A central place for re-use of information technology within government":
    "A central place for re-use of information technology within government",
  "An initiative of": "An initiative of",
  "Privacy declaration": "Privacy declaration",
  "All Open Catalog components": "All Open Catalog components",
  "View all components": "View all components",
  "One central place for reuse of information technology within the government":
    "One central place for reuse of information technology within the government",
  "No components found with active filters": "No components found with active filters",
  "Back to components": "Back to components",
  "View component on GitHub": "View component on GitHub",
  "Schema's": "Schema's",
  "View component": "View component",
  "Check out the repository on GitHub": "Check out the repository on GitHub",
  "Search all components": "Search all components",
  "Data models": "Data models",
  "API's": "API's",
  "About OpenCatalogi": "About OpenCatalogi",
  "This file comes from a 3rd party and can potentially be harmfull for your PC. Are you sure you want to download this?":
    "This file comes from a 3rd party and can potentially be harmfull for your PC. Are you sure you want to download this?",
  "Go back": "Go back",
  "Management organization": "Management organization",
  "No rating available": "No rating available",
  "Score calculation": "Score calculation",
  "There is no organization available": "There is no organization available",
  "No organization found": "No organization found",
  "There is no description available": "There is no description available",
  "My catalogi": "My Catalogi",
  "Software products": "Software products",
  "Back to applications": "Back to applications",
  "Back to categories": "Back to categories",
  "Customer and chain interaction": "Customer and chain interaction",
  "Components overview": "Components overview",
  "No description available": "No description available",
  "Search term": "Search term",
  "Active Filters": "Active Filters",
  "Submit component or organization": "Submit component or organization",
  "Register your component or organization": "Register your component or organization",
  "Repository url of your component or organization": "Repository url of your component or organization",
  "Oops, something went wrong. Please make sure you're using a valid repository URL or try again later.":
    "Oops, something went wrong. Please make sure you're using a valid repository URL or try again later.",
  "View Repository": "View Repository",
  "Already have a repository URL? Then register it immediately.":
    "Already have a repository URL? Then register it immediately.",
  "Owned, supported and used components": "Owned, supported and used components",
  "No website found": "No website found",
  "No repository found": "No repository found",
  "Back to organizations": "Back to organizations",
  "Show more": "Show more",
  "Show less": "Show less",
  "Opens a new window": "Opens a new window",
  "Markdown file": "Markdown file",
  "No markdown file found, make sure that the query param link is filled":
    "No markdown file found, make sure that the query param link is filled",
  "All components": "All components",
  "Scroll left": "Scroll left",
  "Scroll right": "Scroll right",
  "Next page": "Next page",
  "Previous page": "Previous page",
  "Select UPL": "Select UPL",
  "Select organization": "Select organization",
  "Select category": "Select category",
  "Select license": "Select license",
  "Select business function": "Select business function",
  "Select business services": "Select business services",
  "Select reference components": "Select reference components",
  "Hide forks": "Hide forks",
  "Hide obsolete": "Hide obsolete",
  "Phone number": "Phone number",
  "No results found": "No results found",
  "No results available": "No results available",
  "Results per page": "Results per page",
  "Select result limit": "Select result limit",
  "Type of support": "Type of support",
  "Unknown size": "Unknown size",
  "Order by rating": "Order by rating",
  "Left scroll button": "Left scroll button",
  "Right scroll button": "Right scroll button",
  "No application found": "No application found",
  "Commonground rating": "Commonground rating",
  "No rating": "No rating",
  "The repository has been submitted successfully.": "The repository has been submitted successfully.",
  "Show Percentages": "Show Percentages",
  "Show Total": "Show Total",
  "More information": "More information"
};
